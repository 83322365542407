/*
This doc maintain list name of all feature flags defined in split.io
Naming of feature in split should follow the structure `fe_pricing_payment_{featureFlagName} eg fe_pricing_payment_cardsign_dynamic_fields
where fe is frontend, pricing is a team, payment is a payment repo and cardsign_dynamic_fields is feature flag name`
*/

const SPLIT_FEATURES = {
  GIFTING_BUSINESS: 'fe_pricing_gifting_business',
  COMPARISON_TABLE: 'fe_pricing_gifting_comparison_table',
  MY_ORDERS: 'fe_pricing_gifting_my_orders',
  BANK_TRANSFER: 'fe_pricing_gifting_bank_transfer',
  TRUST_PLACEMENT_ENABLED: 'fe_pricing_payment_trust_placement_enabled', // use in the payment sdk
  GOOGLE_PAY_3DS: 'fe_pricing_payment_google_pay_3ds_only_web', // use in the payment sdk
};

export default SPLIT_FEATURES;
